import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import NextButton from 'components/parts/NextButton';
import { isIn, pattern, required, validate } from 'utils/validation';
import useGetProfileMaster from 'hooks/Master/useGetProfieMaster';
import { ProfileFormData } from 'types';
import Select from 'components/parts/Select';
import ProgressBar from 'components/parts/ProgressBar';
import { dayOptions, monthOptions, birthYearOptions } from 'utils/options';
import useScrollTop from 'hooks/useScrollTop';
import ItemName from 'components/parts/ItemName';
import styles from './Profile.module.scss';

const Profile: FC = () => {
  // 認証制御
  const auth = useAuth();
  useScrollTop();
  useCheckToken();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ProfileFormData>({
    mode: 'onChange',
    defaultValues: {
      birthyear: '1990',
      birthmonth: '4',
      birthday: '1',
    },
  });

  // 初期値セット
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const profile = res.data.profiles;

      setValue('lastName', !profile.name || !profile.name.last_name ? '' : profile.name.last_name);
      setValue('firstName', !profile.name || !profile.name.first_name ? '' : profile.name.first_name);
      setValue('lastKana', !profile.name || !profile.name.last_kana ? '' : profile.name.last_kana);
      setValue('firstKana', !profile.name || !profile.name.first_kana ? '' : profile.name.first_kana);

      if (profile.sex != null && typeof profile.sex.id === 'number') {
        setValue('sex', String(profile.sex.id));
      }

      if (typeof profile.birthday === 'string') {
        const birthday = new Date(profile.birthday);
        setValue('birthyear', String(birthday.getFullYear()));
        setValue('birthmonth', String(birthday.getMonth() + 1));
        setValue('birthday', String(birthday.getDate()));
      }

      if (profile.countries != null && typeof profile.countries.id === 'number') {
        setValue('countryId', String(profile.countries.id));
      }

      if (
        profile.countries != null &&
        Array.isArray(profile.countries.detail) &&
        profile.countries.detail.length > 0 &&
        profile.countries.detail[0]?.id
      ) {
        setValue('regionId', String(profile.countries.detail[0].id));
      }

      if (typeof profile.country_num === 'number') {
        setValue('countryNum', String(profile.country_num));
      }

      setLoading(false);
    };

    if (auth.id) {
      req();
    }
  }, [auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: ProfileFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();
    let result = true;

    const birthday = new Date(`${data.birthyear}/${data.birthmonth}/${data.birthday}`);
    if (
      !(
        birthday.getFullYear() === Number(data.birthyear) &&
        birthday.getMonth() + 1 === Number(data.birthmonth) &&
        birthday.getDate() === Number(data.birthday)
      )
    ) {
      // 生年月日形式エラー
      setError('birthyear', {
        type: 'format',
        message: '生年月日は正しい形式で入力してください。',
      });
      setError('birthmonth', {
        type: 'format',
        message: '生年月日は正しい形式で入力してください。',
      });
      setError('birthday', {
        type: 'format',
        message: '生年月日は正しい形式で入力してください。',
      });
      result = false;
    }

    if (!result) {
      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    let params: Record<string, any> = {
      user: {
        phone: data.phone,
        country_num: data.countryNum,
      },
      profile: {
        last_name: data.lastName,
        first_name: data.firstName,
        last_kana: data.lastKana,
        first_kana: data.firstKana,
        country_id: data.countryId,
        region_id: data.regionId,
        sex: data.sex,
      },
    };

    if (birthday > new Date()) {
      // 生年月日形式エラー
      setError('birthyear', {
        type: 'future',
        message: '生年月日に未来の日付が入力されています。',
      });
      setError('birthmonth', {
        type: 'future',
        message: '生年月日に未来の日付が入力されています。',
      });
      setError('birthday', {
        type: 'future',
        message: '生年月日に未来の日付が入力されています。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    params = {
      ...params,
      profile: {
        ...params.profile,
        birthday: `${data.birthyear}-${data.birthmonth}-${data.birthday}`,
      },
    };

    const res = await axios.put('/api/v18/users/profiles', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      // 処理中フラグを折る
      processing.current = false;
      // 次のページに飛ばす (学歴)
      navigate('/education');
    }
  };

  const countryId = watch('countryId');
  const { countries, regions } = useGetProfileMaster(navigate);

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={1} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>基本情報</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>氏名</ItemName>
                    <span className={`${styles.privateSp}`}>非公開</span>
                  </th>
                  <td>
                    <div className={`${styles.formGroup} ${styles.name}`}>
                      <div className={`${styles.formGroupLeft}`}>
                        <span className={`${styles.marginRight8}`}>姓&ensp;</span>
                        <input
                          className={errors?.lastName?.message ? styles.errorForm : ''}
                          type="text"
                          placeholder="姓を入力してください"
                          {...register('lastName', {
                            required: required('姓'),
                          })}
                        />
                      </div>
                      <div className={`${styles.formGroupRight}`}>
                        <span className={`${styles.marginRight8}`}>名&ensp;</span>
                        <input
                          className={errors?.firstName?.message ? styles.errorForm : ''}
                          type="text"
                          placeholder="名を入力してください"
                          {...register('firstName', {
                            required: required('名'),
                          })}
                        />
                      </div>
                      <div className={`${styles.privatePc}`}>非公開</div>
                    </div>
                    <p className={styles.errorMessage}>{errors?.lastName?.message}</p>
                    <p className={styles.errorMessage}>{errors?.firstName?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>フリガナ</ItemName>
                    <span className={`${styles.privateSp}`}>非公開</span>
                  </th>
                  <td>
                    <div className={`${styles.formGroup} ${styles.name}`}>
                      <div className={`${styles.formGroupLeft}`}>
                        <span className={`${styles.marginRight8} ${styles.whiteSpaceNowrap}`}>セイ</span>
                        <input
                          className={errors?.lastKana?.message ? styles.errorForm : ''}
                          type="text"
                          placeholder="セイを入力してください"
                          {...register('lastKana', {
                            required: required('セイ', true, 'をカタカナで入力してください。'),
                            pattern: pattern(/^[ァ-ヶー]+$/, 'セイ', 'をカタカナで入力してください。'),
                          })}
                        />
                      </div>
                      <div className={`${styles.formGroupRight}`}>
                        <span className={`${styles.marginRight8} ${styles.whiteSpaceNowrap}`}>メイ</span>
                        <input
                          className={errors?.firstKana?.message ? styles.errorForm : ''}
                          type="text"
                          placeholder="メイを入力してください"
                          {...register('firstKana', {
                            required: required('メイ', true, 'をカタカナで入力してください。'),
                            pattern: pattern(/^[ァ-ヶー]+$/, 'メイ', 'をカタカナで入力してください。'),
                          })}
                        />
                      </div>
                      <div className={`${styles.privatePc}`}>非公開</div>
                    </div>
                    <p className={styles.errorMessage}>{errors?.lastKana?.message}</p>
                    <p className={styles.errorMessage}>{errors?.firstKana?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>性別</ItemName>
                  </th>
                  <td>
                    <span className={`${styles.radioButton} ${styles.marginRight8}`}>
                      <input
                        id="sex_1"
                        className={`${styles.radioButtonInput}`}
                        type="radio"
                        value="1"
                        {...register('sex', {
                          required: required('性別'),
                          validate: {
                            format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                          },
                        })}
                      />
                      <label className={`${styles.radioButtonLabel}`} htmlFor="sex_1">
                        男性
                      </label>
                    </span>
                    <span className={`${styles.radioButton} ${styles.marginRight8}`}>
                      <input
                        id="sex_2"
                        className={`${styles.radioButtonInput}`}
                        type="radio"
                        value="2"
                        {...register('sex', {
                          required: required('性別'),
                          validate: {
                            format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                          },
                        })}
                      />
                      <label className={`${styles.radioButtonLabel}`} htmlFor="sex_2">
                        女性
                      </label>
                    </span>
                    <span className={`${styles.radioButton}`}>
                      <input
                        id="sex_3"
                        className={`${styles.radioButtonInput}`}
                        type="radio"
                        value="3"
                        {...register('sex', {
                          required: required('性別'),
                          validate: {
                            format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                          },
                        })}
                      />
                      <label className={`${styles.radioButtonLabel}`} htmlFor="sex_3">
                        その他
                      </label>
                    </span>
                    <p className={styles.errorMessage}>{errors?.sex?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>生年月日</ItemName>
                  </th>
                  <td>
                    <div className={`${styles.birth} ${styles.formGroup}`}>
                      <div className={`${styles.formGroupLeft}`}>
                        <Select
                          className={errors?.birthyear?.message ? styles.errorForm : ''}
                          options={birthYearOptions()}
                          control={control}
                          useFormRegisterReturn={register('birthyear', {
                            required: required('生年月日'),
                          })}
                          placeholder="年"
                        />
                        <span className={`${styles.marginLeft8}`}>年</span>
                      </div>
                      <div className={`${styles.formGroupCenter}`}>
                        <Select
                          className={errors?.birthmonth?.message ? styles.errorForm : ''}
                          options={monthOptions}
                          control={control}
                          useFormRegisterReturn={register('birthmonth', {
                            required: required('生年月日'),
                          })}
                          placeholder="月"
                        />
                        <span className={`${styles.marginLeft8}`}>月</span>
                      </div>
                      <div className={`${styles.formGroupRight}`}>
                        <Select
                          className={errors?.birthday?.message ? styles.errorForm : ''}
                          options={dayOptions}
                          control={control}
                          useFormRegisterReturn={register('birthday', {
                            required: required('生年月日'),
                          })}
                          placeholder="日"
                        />
                        <span className={`${styles.marginLeft8}`}>日</span>
                      </div>
                    </div>
                    <p className={styles.errorMessage}>
                      {errors?.birthyear?.message || errors?.birthmonth?.message || errors?.birthday?.message}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>居住国</ItemName>
                  </th>
                  <td>
                    {countries.map((country, index) => {
                      return (
                        <span
                          key={country.id}
                          className={`${styles.radioButton} ${
                            countries.length - 1 !== index ? styles.marginRight8 : ''
                          }`}
                        >
                          <input
                            id={`country_${country.id}`}
                            className={`${styles.radioButtonInput}`}
                            type="radio"
                            value={country.id}
                            {...register('countryId', {
                              required: true,
                              validate: {
                                format: (value) =>
                                  validate(
                                    isIn(
                                      value,
                                      countries.map((c) => c.id),
                                    ),
                                    '居住国',
                                  ),
                              },
                              onChange: () => {
                                // お住まいのエリア初期化
                                setValue('regionId', '');
                                clearErrors('regionId');
                              },
                            })}
                          />
                          <label className={styles.radioButtonLabel} htmlFor={`country_${country.id}`}>
                            {country.name}
                          </label>
                        </span>
                      );
                    })}
                    <p className={styles.errorMessage}>{errors?.countryId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>居住地域</ItemName>
                  </th>
                  <td>
                    <div className={`${styles.widthPc400}`}>
                      {!loading && countryId && (countryId === '99' || regions.length > 0) && (
                        <Select
                          className={errors?.regionId?.message ? styles.errorForm : ''}
                          options={regions.filter((r) => r.countryId === countryId)}
                          placeholder="選択してください"
                          control={control}
                          useFormRegisterReturn={register('regionId', {
                            // お住まいの国がその他でない場合は必須とする
                            required: required('居住地域', countryId !== '99'),
                            validate: {
                              format: (value) =>
                                validate(
                                  countryId === '99' ||
                                    isIn(
                                      value,
                                      regions.map((r) => r.id),
                                    ),
                                  '居住地域',
                                ),
                            },
                          })}
                        />
                      )}
                    </div>
                    <p className={styles.errorMessage}>{errors?.regionId?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Profile;
