import { UseFormSetError } from 'react-hook-form';
import { MypageEditFormData } from 'types';
import axios from 'utils/axios';

/**
 * 職務経歴を更新する
 */
const updateWorkHistories = async (
  setError: UseFormSetError<MypageEditFormData>,
  userId: string,
  token: string | null,
  data: MypageEditFormData,
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, wh] of data.workHistories.entries()) {
    let params: Record<string, any> = {
      company_name: wh.companyName,
      employee_type_id: wh.employeeTypeId,
      work_position_id: wh.workPositionId,
      salary: wh.salary,
      industry_child_id: wh.industryChildId,
      occupation_child_id: wh.occupationChildId,
      outline: wh.outline,
    };

    const joinDate = new Date(`${wh.joinYear}/${wh.joinMonth}/1`);
    if (!(joinDate.getFullYear() === Number(wh.joinYear) && joinDate.getMonth() + 1 === Number(wh.joinMonth))) {
      // 在籍期間形式エラー
      setError(`workHistories.${index}.joinYear`, {
        type: 'format',
        message: '在籍期間は正しい形式で入力してください。',
      });
      setError(`workHistories.${index}.joinMonth`, {
        type: 'format',
        message: '在籍期間は正しい形式で入力してください。',
      });
      return false;
    }

    if (joinDate > new Date()) {
      // 在籍期間形式エラー
      setError(`workHistories.${index}.joinYear`, {
        type: 'future',
        message: '在籍期間に未来の日付が入力されています。',
      });
      setError(`workHistories.${index}.joinMonth`, {
        type: 'future',
        message: '在籍期間に未来の日付が入力されています。',
      });
      return false;
    }

    if (wh.leaveYear && wh.leaveMonth) {
      const leaveDate = new Date(`${wh.leaveYear}/${wh.leaveMonth}/1`);
      if (!(leaveDate.getFullYear() === Number(wh.leaveYear) && leaveDate.getMonth() + 1 === Number(wh.leaveMonth))) {
        // 在籍期間形式エラー
        setError(`workHistories.${index}.leaveYear`, {
          type: 'format',
          message: '在籍期間は正しい形式で入力してください。',
        });
        setError(`workHistories.${index}.leaveMonth`, {
          type: 'format',
          message: '在籍期間は正しい形式で入力してください。',
        });
        return false;
      }

      if (leaveDate > new Date()) {
        // 在籍期間形式エラー
        setError(`workHistories.${index}.leaveYear`, {
          type: 'future',
          message: '在籍期間に未来の日付が入力されています。',
        });
        setError(`workHistories.${index}.leaveMonth`, {
          type: 'future',
          message: '在籍期間に未来の日付が入力されています。',
        });
        return false;
      }

      if (joinDate > leaveDate) {
        // 在籍開始期間 > 在籍終了期間エラー
        setError(`workHistories.${index}.leaveYear`, {
          type: 'future',
          message: '在籍終了年月に在籍開始年月より過去の年月が入力されています。',
        });
        setError(`workHistories.${index}.leaveMonth`, {
          type: 'future',
          message: '在籍終了年月に在籍開始年月より過去の年月が入力されています。',
        });
        return false;
      }

      params = {
        ...params,
        leave_year: wh.leaveYear,
        leave_month: wh.leaveMonth,
      };
    } else {
      params = {
        ...params,
        leave_year: '',
        leave_month: '',
      };
    }

    params = {
      ...params,
      join_year: wh.joinYear,
      join_month: wh.joinMonth,
    };

    if (wh?.id) {
      // 更新
      // eslint-disable-next-line no-await-in-loop
      await axios.put(`/api/v18/users/work-histories/${wh.id}`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      // 初回登録
      // eslint-disable-next-line no-await-in-loop
      await axios.post('/api/v18/users/work-histories', params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  return true;
};

export default updateWorkHistories;
