import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import styles from './Inquiry.module.scss';

const Inquiry: FC = () => {
  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <MuiLink underline="hover" color="inherit" to="/setting" component={Link}>
            設定
          </MuiLink>
          <Typography color="text.primary">運営へお問い合わせ</Typography>
        </Breadcrumbs>
        <h1 className={`${styles.titleMain}`}>運営へお問い合わせ</h1>
        <section className={`${styles.panelDefault}`}>
          <a
            className={`${styles.panelDefaultTitle}`}
            href="https://jiayou.jp/contact"
            target="_blank"
            rel="noreferrer"
          >
            お問い合わせ
          </a>
          <Link className={`${styles.panelDefaultTitle}`} to="/withdrawal">
            退会
          </Link>
        </section>
      </div>
    </main>
  );
};

export default Inquiry;
