import { CircularProgress } from '@mui/material';
import NextButton from 'components/parts/NextButton';
import useAuth from 'hooks/useAuth';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isDuplicated, isIn, required, validate } from 'utils/validation';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import useGetSkillMaster from 'hooks/Master/useGetSkillMaster';
import { SkillFormData, SkillsFormData } from 'types';
import Select from 'components/parts/Select';
import ProgressBar from 'components/parts/ProgressBar';
import AddSkillButton from 'components/parts/AddSkillButton';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import getSkills from 'requests/getSkills';
import ItemName from 'components/parts/ItemName';
import styles from './Skill.module.scss';

const Skill: FC = () => {
  const auth = useAuth();
  useScrollTop();
  useCheckToken();
  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isValid },
  } = useForm<SkillsFormData>({
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'skills',
    keyName: 'key',
    control,
  });

  const addSkill = useCallback(
    () =>
      append({
        skill: '',
        skillLevel: '',
      }),
    [append],
  );

  const [loadingForPrepare, setLoadingForPrepare] = useState(true);

  const { skillLevels } = useGetSkillMaster(navigate);

  // 初期値セット
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const occupationSkills = res.data.skills.occupation_skills;
      if (!Array.isArray(occupationSkills)) {
        return;
      }

      const skillsData: SkillFormData[] = [];
      occupationSkills.forEach((os: any) => {
        let skill = os.name.split(' ')[0];
        if (typeof os.id === 'number' && os.id > 0) {
          skill = `${skill}#${os.id}`;
        }
        const skillData = {
          skill,
          skillLevel: String(os.level_id),
        };
        skillsData.push(skillData);
      });

      setValue('skills', skillsData);
      for (let i = skillsData.length; i < 3; i += 1) {
        // 初期表示が3つになるようにスキルフォームを追加する
        addSkill();
      }
    };

    if (auth.id) {
      req().then(() => {
        setLoadingForPrepare(false);
      });
    }
  }, [addSkill, auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: SkillsFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    let result = true;
    data.skills.forEach((s, index) => {
      if (s.skill) {
        if (!s.skillLevel) {
          setError(`skills.${index}.skillLevel`, {
            type: 'required',
            message: '経験年数を入力してください。',
          });
          result = false;
        }
      }
      if (s.skillLevel) {
        if (!s.skill) {
          setError(`skills.${index}.skill`, {
            type: 'required',
            message: 'スキルを入力してください。',
          });
          result = false;
        }
        if (
          !isIn(
            s.skillLevel,
            skillLevels.map((sl) => sl.id),
          )
        ) {
          setError(`skills.${index}.skillLevel`, {
            type: 'format',
            message: '経験年数は正しい形式で入力してください。',
          });
        }
      }
    });

    if (!result) {
      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    const occupationSkills = data.skills
      .filter((s) => s.skill && s.skillLevel)
      .map((s) => {
        let param: Record<string, any> = {
          occupation_skill_level_id: s.skillLevel,
        };

        const re = /#[0-9]*$/;
        const match = re.exec(s.skill);
        if (match && match.length > 0) {
          param = {
            ...param,
            occupation_skill: null,
            occupation_skill_id: match[0].replace('#', ''),
          };
        } else {
          param = {
            ...param,
            occupation_skill: s.skill,
            occupation_skill_id: null,
          };
        }

        return param;
      });

    const params = {
      occupation_skills: occupationSkills,
    };

    const token = getToken();
    const res = await axios.put('/api/v18/users/skills', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 処理中フラグを折る
    processing.current = false;
    if (res.status === 200 || res.status === 201) {
      // 次のページに飛ばす (希望情報)
      navigate('/request');
    }
  };

  const skillsData = watch('skills');

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={6} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>スキル</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th className={`${styles.skillTitleSp}`}>
                    <ItemName required>スキルと経験年数</ItemName>
                  </th>
                  <td className={`${styles.skillContent}`}>
                    {!loadingForPrepare && skillLevels.length > 0 ? (
                      fields.map((field, index) => {
                        return (
                          <div className={`${styles.skillWrapper}`} key={field.key}>
                            <div className={`${styles.skillTitlePc}`}>
                              {index === 0 && <ItemName required>スキルと経験年数</ItemName>}
                            </div>
                            <div className={styles.skill}>
                              <div className={`${styles.skillFormsWrapper}`}>
                                <div
                                  className={`${styles.skillForm} ${
                                    errors?.skills && errors.skills[index]?.skill?.message ? styles.errorForm : ''
                                  } `}
                                >
                                  <Controller
                                    control={control}
                                    name={`skills.${index}.skill`}
                                    rules={{
                                      required: required('スキル', index === 0),
                                      validate: {
                                        duplicated: (value) =>
                                          validate(
                                            value === '' ||
                                              isDuplicated(
                                                value,
                                                skillsData.map((s) => s.skill),
                                              ),
                                            'スキル',
                                            'が重複しています。',
                                          ),
                                      },
                                    }}
                                    render={({ field: { onChange, value } }) => {
                                      const skillValue = value
                                        ? {
                                            value,
                                            label: value,
                                          }
                                        : [];
                                      return (
                                        <AsyncCreatableSelectInput
                                          placeholder="入力/選択してください"
                                          loadOptions={(inputValue: any) => getSkills(inputValue, navigate)}
                                          value={skillValue}
                                          onChange={(val: any) => onChange(val?.value ?? '')}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                <div className={styles.skillLevelForm}>
                                  <Select
                                    control={control}
                                    className={
                                      errors?.skills && errors.skills[index]?.skillLevel?.message
                                        ? styles.errorForm
                                        : ''
                                    }
                                    placeholder="年"
                                    options={skillLevels}
                                    useFormRegisterReturn={register(`skills.${index}.skillLevel`, {
                                      required: required('経験年数', index === 0),
                                      validate: {
                                        format: (value) =>
                                          value === '' ||
                                          validate(
                                            isIn(
                                              value,
                                              skillLevels.map((sl) => sl.id),
                                            ),
                                            '経験年数',
                                          ),
                                      },
                                    })}
                                  />
                                </div>
                                {index > 0 && (
                                  <div className={styles.removeButton}>
                                    {/* eslint-disable-next-line */}
                                    <button type="button" onClick={() => remove(index)}>
                                      <RemoveCircleIcon fontSize="large" />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className={styles.errorField}>
                                {/* エラーメッセージ */}
                                {errors?.skills && (
                                  <>
                                    <p className={styles.errorMessage}>{errors.skills[index]?.skill?.message}</p>
                                    <p className={styles.errorMessage}>{errors.skills[index]?.skillLevel?.message}</p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <CircularProgress />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.addWorkHistoryWrapper}>
              <AddSkillButton handleClick={addSkill} />
            </div>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Skill;
