import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import useAuth from './useAuth';

type Profile = {
  lastName: string;
  firstName: string;
  lastKana: string;
  firstKana: string;
  sex: string;
  birthday: string;
  phone: string;
  country: string;
  region: string;
};

type WorkStatus = string;

type WorkHistory = {
  companyName: string;
  employeeType: string;
  workPosition: string;
  join: string;
  leave: string;
  salary: string;
  industryChild: string;
  occupationChild: string;
  outline: string;
};

type Skill = {
  name: string;
}[];

type Education = {
  educationName: string;
  schoolName: string;
  department: string;
  graduateDate: string;
};

type Language = {
  native: string;
  acquire: {
    name: string;
  }[];
  jlpt: string;
  hsk: string;
  chinese: string;
  toeic: string;
  toefl: string;
};

type Qualification = {
  name: string;
}[];

type Request = {
  salary: string;
  industry: {
    name: string;
  }[];
  occupation: {
    name: string;
  }[];
  region: {
    name: string;
  }[];
  other: string;
};

type UserDescription = {
  address: string;
  authenticated: boolean | undefined;
  userOriginalId: string;
};

const setUserDescriptionData = async (token: string, setUserDescription: Dispatch<SetStateAction<UserDescription>>) => {
  const res = await axios.get('/api/v18/user/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { user } = res.data;

  const tmp: UserDescription = {
    address: user.email,
    authenticated: user.is_mail_authenticated,
    userOriginalId: user.user_original_id,
  };

  setUserDescription(tmp);
};

const setProfileData = (profile: any, setProfile: Dispatch<SetStateAction<Profile>>) => {
  const profileData = {
    lastName: profile?.name?.last_name ?? '-',
    firstName: profile?.name?.first_name ?? '-',
    lastKana: profile?.name?.last_kana ?? '-',
    firstKana: profile?.name?.first_kana ?? '-',
    sex: profile?.sex?.name ?? '-',
    birthday: '-',
    phone: profile?.phone ?? '-',
    country: profile?.countries?.name ?? '-',
    region: '-',
  };

  if (profile.birthday) {
    const birthday = new Date(profile.birthday);
    profileData.birthday = `${birthday.getFullYear()}年${birthday.getMonth() + 1}月${birthday.getDate()}日`;
  }

  if (
    profile?.countries &&
    Array.isArray(profile.countries?.detail) &&
    profile.countries.detail.length === 1 &&
    profile.countries.detail[0]?.name
  ) {
    profileData.region = profile.countries.detail[0].name;
  }

  setProfile(profileData);
};

const setWorkStatusData = (workStatus: any, setWorkStatus: Dispatch<SetStateAction<WorkStatus>>) => {
  setWorkStatus(workStatus?.work_status_id?.name ?? '-');
};

const setWorkHistoryData = (workHistories: any, setWorkHistories: Dispatch<SetStateAction<WorkHistory[]>>) => {
  const workHistoriesData = workHistories.map((wh: any) => {
    const workHistoryData = {
      companyName: wh?.company_name ?? '-',
      employeeType: wh?.employee_type?.name ?? '-',
      workPosition: wh?.work_position?.name ?? '-',
      join: '-',
      leave: '-',
      salary: wh?.salary ?? '-',
      industryChild: '-',
      occupationChild: '-',
      outline: wh?.outline ?? '-',
    };

    if (wh?.join_year && wh?.join_month) {
      workHistoryData.join = `${wh.join_year}年${wh.join_month}月`;

      if (wh?.leave_year && wh?.leave_month) {
        workHistoryData.leave = `${wh.leave_year}年${wh.leave_month}月`;
      } else {
        workHistoryData.leave = '';
      }
    }

    if (Array.isArray(wh.industry) && wh.industry.length > 0) {
      const industry = wh.industry[0];
      if ('detail' in industry && Array.isArray(industry.detail) && industry.detail.length > 0) {
        const detail = industry.detail[0];
        workHistoryData.industryChild = detail.name;
      }
    }
    if (Array.isArray(wh.occupation) && wh.occupation.length > 0) {
      const occupation = wh.occupation[0];
      if ('detail' in occupation && Array.isArray(occupation.detail) && occupation.detail.length > 0) {
        const detail = occupation.detail[0];
        workHistoryData.occupationChild = detail.name;
      }
    }

    return workHistoryData;
  });

  setWorkHistories(workHistoriesData);
};

const setSkillData = (occupationSkills: any, setSkill: Dispatch<SetStateAction<Skill>>) => {
  const skillData = occupationSkills.map((os: any) => ({ name: os.name }));
  setSkill(skillData);
};

const setEducationData = (education: any, setEducation: Dispatch<SetStateAction<Education>>) => {
  const educationData: Education = {
    educationName: education?.education_id?.name ?? '-',
    schoolName: education?.school_name ?? '-',
    department: education?.department ?? '-',
    graduateDate: '-',
  };

  if (education?.graduate_year && education?.graduate_month) {
    educationData.graduateDate = `${education.graduate_year}年${education.graduate_month}月`;
  }

  setEducation(educationData);
};

const setLanguageData = (languages: any, acquireLanguages: any, setLanguage: Dispatch<SetStateAction<Language>>) => {
  const languageData: Language = {
    native: languages?.language_id?.name ?? '-',
    acquire: [],
    jlpt: languages?.test_jlpt_grade_id?.name ?? '-',
    hsk: languages?.test_hsk_grade_id?.name ?? '-',
    chinese: languages?.test_chinese_grade_id?.name ?? '-',
    toeic: languages?.toeic_score ?? '-',
    toefl: languages?.toefl_score ?? '-',
  };

  if (Array.isArray(acquireLanguages) && acquireLanguages.length > 0) {
    languageData.acquire = acquireLanguages.map((al) => ({
      name: al.name,
    }));
  }

  setLanguage(languageData);
};

const setQualificationData = (qualifications: any, setQualification: Dispatch<SetStateAction<Qualification>>) => {
  const qualificationData: Qualification = qualifications.map((q: any) => ({
    name: q.name,
  }));

  setQualification(qualificationData);
};

const setRequestData = (requests: any, setRequest: Dispatch<SetStateAction<Request>>) => {
  const requestData: Request = {
    salary: requests?.salary?.name ?? '-',
    industry: [],
    occupation: [],
    region: [],
    other: requests?.other?.content ? requests.other.content : '-',
  };

  if (Array.isArray(requests?.industries) && requests.industries.length > 0) {
    const industryNames = requests.industries
      .map((i: any) =>
        i.detail.map((d: any) => ({
          name: d.name,
        })),
      )
      .flat();

    requestData.industry = industryNames;
  }

  if (Array.isArray(requests?.occupations) && requests.occupations.length > 0) {
    const occupationNames = requests.occupations
      .map((o: any) =>
        o.detail.map((d: any) => ({
          name: d.name,
        })),
      )
      .flat();

    requestData.occupation = occupationNames;
  }

  if (Array.isArray(requests?.locations) && requests.locations.length > 0) {
    const locationNames = requests.locations
      .map((l: any) =>
        l.detail.map((d: any) => ({
          name: l.name !== 'その他' ? `${l.name} / ${d.name}` : d.name,
        })),
      )
      .flat();

    requestData.region = locationNames;
  }

  setRequest(requestData);
};

const useGetMypage = (): {
  profile: Profile;
  workStatus: WorkStatus;
  workHistories: WorkHistory[];
  skill: Skill;
  education: Education;
  language: Language;
  qualification: Qualification;
  request: Request;
  userDescription: UserDescription;
} => {
  const auth = useAuth();

  const [profile, setProfile] = useState<Profile>({
    lastName: '-',
    firstName: '-',
    lastKana: '-',
    firstKana: '-',
    sex: '-',
    birthday: '-',
    phone: '-',
    country: '-',
    region: '-',
  });
  const [workStatus, setWorkStatus] = useState<WorkStatus>('-');
  const [workHistories, setWorkHistories] = useState<WorkHistory[]>([
    {
      companyName: '-',
      employeeType: '-',
      workPosition: '-',
      join: '-',
      leave: '-',
      salary: '-',
      industryChild: '-',
      occupationChild: '-',
      outline: '-',
    },
  ]);
  const [skill, setSkill] = useState<Skill>([]);
  const [education, setEducation] = useState<Education>({
    educationName: '-',
    schoolName: '-',
    department: '-',
    graduateDate: '-',
  });
  const [language, setLanguage] = useState<Language>({
    native: '-',
    acquire: [],
    jlpt: '-',
    hsk: '-',
    chinese: '-',
    toeic: '-',
    toefl: '-',
  });
  const [qualification, setQualification] = useState<Qualification>([]);
  const [request, setRequest] = useState<Request>({
    salary: '-',
    industry: [],
    occupation: [],
    region: [],
    other: '-',
  });
  const [userDescription, setUserDescription] = useState<UserDescription>({
    address: '-',
    authenticated: undefined,
    userOriginalId: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }

      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data?.profiles) {
        const { profiles } = res.data;
        // プロフィール
        setProfileData(profiles, setProfile);

        if (profiles?.work_status) {
          // 就業状況
          setWorkStatusData(profiles.work_status, setWorkStatus);
        }

        if (profiles?.school) {
          // 学歴
          setEducationData(profiles?.school, setEducation);
        }
      }

      if (Array.isArray(res.data?.work_histories) && res.data.work_histories.length > 0) {
        // 職務経歴
        setWorkHistoryData(res.data?.work_histories, setWorkHistories);
      }

      if (res.data?.skills) {
        const { skills } = res.data;
        if (Array.isArray(skills?.occupation_skills) && skills.occupation_skills.length > 0) {
          // スキル
          setSkillData(skills?.occupation_skills, setSkill);
        }

        if (skills?.skill || (Array.isArray(skills?.acquire_languages) && skills.acquire_languages.length > 0)) {
          // 語学
          setLanguageData(skills?.skill, skills?.acquire_languages, setLanguage);
        }

        if (Array.isArray(skills?.qualifications) && skills.qualifications.length > 0) {
          // 資格
          setQualificationData(skills.qualifications, setQualification);
        }
      }

      if (res.data?.requests) {
        // 希望条件
        setRequestData(res.data.requests, setRequest);
      }

      // メールアドレス
      await setUserDescriptionData(token, setUserDescription);
    };

    if (auth.id) {
      req();
    }
  }, [auth.id, navigate]);

  return {
    profile,
    workStatus,
    workHistories,
    skill,
    education,
    language,
    qualification,
    request,
    userDescription,
  };
};

export default useGetMypage;
