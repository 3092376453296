import { CircularProgress } from '@mui/material';
import AddWorkHistoryButton from 'components/parts/AddWorkHistoryButton';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import ItemName from 'components/parts/ItemName';
import Select from 'components/parts/Select';
import Textarea from 'components/parts/Textarea';
import { FC, useCallback } from 'react';
import { Control, Controller, FormState, UseFieldArrayReturn, UseFormRegister, UseFormWatch } from 'react-hook-form';
import getCompanyNames from 'requests/getCompanyNames';
import { MypageEditFormData, Optgroup, Option, WorkHistoriesFormData } from 'types';
import axios from 'utils/axios';
import { monthOptions, workOrGraduationYearOptions } from 'utils/options';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import styles from './WorkHistory.module.scss';

type Props = {
  workPositions: Option[];
  employeeTypes: Option[];
  industries: Optgroup[];
  occupations: Optgroup[];
  workHistories: WorkHistoriesFormData['workHistories'];
  control: Control<MypageEditFormData>;
  loadingInitial: boolean;
  register: UseFormRegister<MypageEditFormData>;
  watch: UseFormWatch<MypageEditFormData>;
  fields: UseFieldArrayReturn<MypageEditFormData, 'workHistories', 'key'>['fields'];
  append: UseFieldArrayReturn<MypageEditFormData, 'workHistories', 'key'>['append'];
  remove: UseFieldArrayReturn<MypageEditFormData, 'workHistories', 'key'>['remove'];
  errors: FormState<MypageEditFormData>['errors'];
};

const WorkHistory: FC<Props> = ({
  workPositions,
  employeeTypes,
  industries,
  occupations,
  workHistories,
  control,
  loadingInitial,
  register,
  watch,
  fields,
  remove,
  errors,
}) => {
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...workHistories[index],
    };
  });

  useCheckToken();
  const deleteWorkHistory = async (index: number) => {
    const words = `職務経歴${index + 1}を削除しますか？`;
    // eslint-disable-next-line no-alert
    if (window.confirm(words)) {
      const workHistoryId = watch(`workHistories.${index}.id`);
      if (typeof workHistoryId !== 'undefined') {
        const token = getToken();
        await axios.delete(`/api/v18/users/work-histories/${workHistoryId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      remove(index);
    }
  };

  return (
    <>
      {controlledFields.map((field, index) => {
        return (
          <section className={`${styles.panelDefault}`} key={field.key}>
            <div className={`${styles.panelDefaultTitle}`}>
              <h2>{`職務情報${index === 0 ? '（直近の所属企業）' : index + 1}`}</h2>
              {index > 0 && (
                <button type="button" className={styles.deleteWorkHistory} onClick={() => deleteWorkHistory(index)}>
                  削除
                </button>
              )}
            </div>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>在籍企業名</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <div
                          className={`${styles.companyName} ${
                            errors?.workHistories?.[index]?.companyName?.message ? styles.errorForm : ''
                          }`}
                        >
                          <Controller
                            defaultValue={field.companyName}
                            control={control}
                            name={`workHistories.${index}.companyName`}
                            rules={{
                              required: required('企業名'),
                            }}
                            render={({ field: { onChange, value } }) => {
                              const companyNameValue = value
                                ? {
                                    value,
                                    label: value,
                                  }
                                : [];
                              return (
                                <AsyncCreatableSelectInput
                                  placeholder="企業名を入力してください"
                                  loadOptions={getCompanyNames}
                                  value={companyNameValue}
                                  onChange={(val: any) => onChange(val?.value ?? '')}
                                />
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>{errors?.workHistories?.[index]?.companyName?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>雇用形態</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <Select
                          className={errors?.workHistories?.[index]?.employeeTypeId?.message ? styles.errorForm : ''}
                          placeholder="選択してください"
                          options={employeeTypes}
                          control={control}
                          useFormRegisterReturn={register(`workHistories.${index}.employeeTypeId`, {
                            required: required('雇用形態'),
                            validate: {
                              format: (value) =>
                                validate(
                                  isIn(
                                    value,
                                    employeeTypes.map((et) => et.id),
                                  ),
                                  '雇用形態',
                                ),
                            },
                          })}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>{errors?.workHistories?.[index]?.employeeTypeId?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>役職・クラス</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <Select
                          className={errors?.workHistories?.[index]?.workPositionId?.message ? styles.errorForm : ''}
                          placeholder="選択してください"
                          options={workPositions}
                          control={control}
                          useFormRegisterReturn={register(`workHistories.${index}.workPositionId`, {
                            required: required('役職・クラス'),
                            validate: {
                              format: (value) =>
                                validate(
                                  isIn(
                                    value,
                                    workPositions.map((wp) => wp.id),
                                  ),
                                  '役職・クラス',
                                ),
                            },
                          })}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>{errors?.workHistories?.[index]?.workPositionId?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>企業の在籍期間</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <div className={`${styles.yearMonth} ${styles.formGroup}`}>
                          <div className={styles.formGroupLeft}>
                            <Select
                              className={errors?.workHistories?.[index]?.joinYear?.message ? styles.errorForm : ''}
                              options={workOrGraduationYearOptions()}
                              control={control}
                              useFormRegisterReturn={register(`workHistories.${index}.joinYear`, {
                                required: required('在籍期間'),
                              })}
                              placeholder="年"
                            />
                            <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>年</span>
                            <Select
                              className={errors?.workHistories?.[index]?.joinMonth?.message ? styles.errorForm : ''}
                              options={monthOptions}
                              control={control}
                              useFormRegisterReturn={register(`workHistories.${index}.joinMonth`, {
                                required: required('在籍期間'),
                              })}
                              placeholder="月"
                            />
                            <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>月</span>
                          </div>
                          <div className={styles.formGroupCenter}>〜</div>
                          <div className={styles.formGroupRight}>
                            <Select
                              className={errors?.workHistories?.[index]?.leaveYear?.message ? styles.errorForm : ''}
                              options={workOrGraduationYearOptions()}
                              control={control}
                              useFormRegisterReturn={register(`workHistories.${index}.leaveYear`)}
                              placeholder="年"
                            />
                            <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>年</span>
                            <Select
                              className={errors?.workHistories?.[index]?.leaveMonth?.message ? styles.errorForm : ''}
                              options={monthOptions}
                              control={control}
                              useFormRegisterReturn={register(`workHistories.${index}.leaveMonth`)}
                              placeholder="月"
                            />
                            <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>月</span>
                          </div>
                        </div>
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>
                        {errors?.workHistories?.[index]?.joinYear?.message ||
                          errors?.workHistories?.[index]?.joinMonth?.message ||
                          errors?.workHistories?.[index]?.leaveYear?.message ||
                          errors?.workHistories?.[index]?.leaveMonth?.message}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>年収</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <div className={styles.salaryWrapper}>
                          <input
                            className={errors?.workHistories?.[index]?.salary?.message ? styles.errorForm : ''}
                            type="number"
                            placeholder="年収を入力してください"
                            {...register(`workHistories.${index}.salary`, {
                              required: required('年収'),
                            })}
                          />
                          <span className={`${styles.marginLeft8}`}>万円</span>
                        </div>
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>{errors?.workHistories?.[index]?.salary?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>業界</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <Select
                          className={errors?.workHistories?.[index]?.industryChildId?.message ? styles.errorForm : ''}
                          placeholder="選択してください"
                          optgroups={industries}
                          control={control}
                          useFormRegisterReturn={register(`workHistories.${index}.industryChildId`, {
                            required: required('業界'),
                            validate: {
                              format: (value) =>
                                validate(isIn(value, industries.map((i) => i.options.map((o) => o.id)).flat()), '業界'),
                            },
                          })}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>{errors?.workHistories?.[index]?.industryChildId?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className={styles.tableHeader}>
                        <ItemName required>職種</ItemName>
                      </div>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <Select
                          className={errors?.workHistories?.[index]?.occupationChildId?.message ? styles.errorForm : ''}
                          placeholder="選択してください"
                          optgroups={occupations}
                          control={control}
                          useFormRegisterReturn={register(`workHistories.${index}.occupationChildId`, {
                            required: required('職種'),
                            validate: {
                              format: (value) =>
                                validate(
                                  isIn(value, occupations.map((i) => i.options.map((o) => o.id)).flat()),
                                  '職種',
                                ),
                            },
                          })}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                      <p className={styles.errorMessage}>
                        {errors?.workHistories?.[index]?.occupationChildId?.message}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <ItemName>職務概要</ItemName>
                    </th>
                    <td>
                      {!loadingInitial ? (
                        <Textarea
                          control={control}
                          useFormRegisterReturn={register(`workHistories.${index}.outline`)}
                          placeholder={`具体的なスキルや経験を入力してください。
例）
プロジェクトメンバー10名をマネジメントしながら、新規事業を立ち上げ、2年間で10億円の売り上げを達成した。`}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        );
      })}
    </>
  );
};

const MypageEditWorkHistory: FC<Props> = (props) => {
  const { append } = props;
  const addWorkHistory = useCallback(
    () =>
      append(
        {
          companyName: '',
          joinYear: '2010',
          joinMonth: '4',
          leaveYear: '',
          leaveMonth: '',
          employeeTypeId: '',
          workPositionId: '',
          salary: '',
          industryChildId: '',
          occupationChildId: '',
          outline: '',
        },
        {
          shouldFocus: false,
        },
      ),
    [append],
  );

  const { fields } = props;

  return (
    <>
      {fields.length > 0 && <WorkHistory {...props} />}
      <div className={styles.addWorkHistoryWrapper}>
        <AddWorkHistoryButton handleClick={addWorkHistory} />
      </div>
    </>
  );
};

export default MypageEditWorkHistory;
