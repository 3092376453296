import axios from 'utils/axios';
import { useEffect, useState } from 'react';
import { getToken } from 'utils/token';
import { UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MypageEditFormData, SkillFormData, WorkHistoryFormData } from 'types';

type SetValue = UseFormSetValue<MypageEditFormData>;

const setProfile = (profile: any, setValue: SetValue) => {
  setValue('lastName', !profile.name || !profile.name.last_name ? '' : profile.name.last_name);
  setValue('firstName', !profile.name || !profile.name.first_name ? '' : profile.name.first_name);
  setValue('lastKana', !profile.name || !profile.name.last_kana ? '' : profile.name.last_kana);
  setValue('firstKana', !profile.name || !profile.name.first_kana ? '' : profile.name.first_kana);

  if (profile.sex != null && typeof profile.sex.id === 'number') {
    setValue('sex', String(profile.sex.id));
  }

  if (typeof profile.birthday === 'string') {
    const birthday = new Date(profile.birthday);
    setValue('birthyear', String(birthday.getFullYear()));
    setValue('birthmonth', String(birthday.getMonth() + 1));
    setValue('birthday', String(birthday.getDate()));
  }

  if (profile.countries != null && typeof profile.countries.id === 'number') {
    setValue('countryId', String(profile.countries.id));
  }

  if (
    profile.countries != null &&
    Array.isArray(profile.countries.detail) &&
    profile.countries.detail.length > 0 &&
    profile.countries.detail[0]?.id
  ) {
    setValue('regionId', String(profile.countries.detail[0].id));
  }

  if (typeof profile.phone === 'string') {
    setValue('phone', profile.phone);
  }

  if (typeof profile.country_num === 'number') {
    setValue('countryNum', String(profile.country_num));
  }

  setValue('email', profile.email);
};

const setWorkStatus = (profile: any, setValue: SetValue) => {
  if (
    profile.work_status &&
    profile.work_status.work_status_id &&
    typeof profile.work_status.work_status_id.id === 'number'
  ) {
    setValue('workStatusId', String(profile.work_status.work_status_id.id));
  }
};

const setWorkHistories = (workHistories: any, setValue: SetValue) => {
  const workHistoriesData: WorkHistoryFormData[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const workHistory of workHistories) {
    const workHistoryData: WorkHistoryFormData = {
      id: String(workHistory.id),
      companyName: workHistory.company_name,
      joinYear: '',
      joinMonth: '',
      leaveYear: '',
      leaveMonth: '',
      employeeTypeId: '',
      workPositionId: '',
      salary: workHistory.salary,
      industryChildId: '',
      occupationChildId: '',
      outline: '',
    };

    if (typeof workHistory.join_year === 'number' && workHistory.join_year > 0) {
      workHistoryData.joinYear = String(workHistory.join_year);
    }
    if (typeof workHistory.join_month === 'number' && workHistory.join_month > 0) {
      workHistoryData.joinMonth = String(workHistory.join_month);
    }
    if (typeof workHistory.leave_year === 'number' && workHistory.leave_year > 0) {
      workHistoryData.leaveYear = String(workHistory.leave_year);
    }
    if (typeof workHistory.leave_month === 'number' && workHistory.leave_month > 0) {
      workHistoryData.leaveMonth = String(workHistory.leave_month);
    }
    if (workHistory.employee_type && typeof workHistory.employee_type.id === 'number') {
      workHistoryData.employeeTypeId = String(workHistory.employee_type.id);
    }
    if (workHistory.work_position && typeof workHistory.work_position.id === 'number') {
      workHistoryData.workPositionId = String(workHistory.work_position.id);
    }
    if (Array.isArray(workHistory.industry) && workHistory.industry.length > 0) {
      const industry = workHistory.industry[0];
      if ('detail' in industry && Array.isArray(industry.detail) && industry.detail.length > 0) {
        const detail = industry.detail[0];
        workHistoryData.industryChildId = String(detail.id);
      }
    }
    if (Array.isArray(workHistory.occupation) && workHistory.occupation.length > 0) {
      const occupation = workHistory.occupation[0];
      if ('detail' in occupation && Array.isArray(occupation.detail) && occupation.detail.length > 0) {
        const detail = occupation.detail[0];
        workHistoryData.occupationChildId = String(detail.id);
      }
    }
    if (typeof workHistory.outline === 'string') {
      workHistoryData.outline = workHistory.outline;
    }

    workHistoriesData.push(workHistoryData);
  }

  setValue('workHistories', workHistoriesData, {
    shouldValidate: true,
  });
};

const setSkills = (occupationSkills: any, setValue: SetValue) => {
  const skillsData: SkillFormData[] = [];
  occupationSkills.forEach((os: any) => {
    let skill = os.name.split(' ')[0];
    if (typeof os.id === 'number' && os.id > 0) {
      skill = `${skill}#${os.id}`;
    }
    const skillData = {
      skill,
      skillLevel: String(os.level_id),
    };
    skillsData.push(skillData);
  });

  if (skillsData.length > 0) {
    setValue('skills', skillsData);
  } else {
    setValue('skills', [
      {
        skill: '',
        skillLevel: '',
      },
    ]);
  }
};

const setEducation = (education: any, setValue: SetValue) => {
  if (education.education_id && typeof education.education_id.id === 'number' && education.education_id.id > 0) {
    setValue('educationsId', String(education.education_id.id), {
      shouldValidate: true,
    });
  }

  if (typeof education.school_name === 'string') {
    setValue('schoolName', education.school_name, {
      shouldValidate: true,
    });
  }

  if (typeof education.department === 'string') {
    setValue('department', education.department, {
      shouldValidate: true,
    });
  }

  if (typeof education.graduate_year === 'number') {
    setValue('graduateYear', String(education.graduate_year), {
      shouldValidate: true,
    });
  }

  if (typeof education.graduate_month === 'number') {
    setValue('graduateMonth', String(education.graduate_month), {
      shouldValidate: true,
    });
  }
};

const setLanguage = (skills: any, acquireLanguages: any, setValue: SetValue) => {
  if (skills) {
    if (typeof skills?.language_id?.id === 'number') {
      setValue('languageId', String(skills.language_id.id), {
        shouldValidate: true,
      });
    }

    if (typeof skills?.test_jlpt_grade_id?.id === 'number' && skills.test_jlpt_grade_id.id > 0) {
      setValue('testJlptGradeId', String(skills.test_jlpt_grade_id.id), {
        shouldValidate: true,
      });
    }

    if (typeof skills?.test_hsk_grade_id?.id === 'number' && skills.test_hsk_grade_id.id > 0) {
      setValue('testHskGradeId', String(skills.test_hsk_grade_id.id), {
        shouldValidate: true,
      });
    }

    if (typeof skills?.test_chinese_grade_id?.id === 'number' && skills.test_chinese_grade_id.id > 0) {
      setValue('testChineseGradeId', String(skills.test_chinese_grade_id.id), {
        shouldValidate: true,
      });
    }

    if (typeof skills?.toeic_score === 'number') {
      setValue('toeicScore', String(skills.toeic_score), {
        shouldValidate: true,
      });
    }

    if (typeof skills?.toefl_score === 'number') {
      setValue('toeflScore', String(skills.toefl_score), {
        shouldValidate: true,
      });
    }
  }

  if (acquireLanguages) {
    const acquireLanguageIds = acquireLanguages.map((a: any) => `${a.id}-${a.level_id}`);
    setValue('acquireLanguage', acquireLanguageIds, {
      shouldValidate: true,
    });
  }
};

const setQualifications = (qualifications: any, setValue: SetValue) => {
  const qualificationsIds = qualifications.map((q: any) => `${q.name}#${q.id}`);
  setValue('qualificationIds', qualificationsIds, {
    shouldValidate: true,
  });
};

const setRequest = (requests: any, setValue: SetValue) => {
  if (typeof requests?.salary?.id === 'number' && requests.salary.id > 0) {
    setValue('salaryId', String(requests.salary.id), {
      shouldValidate: true,
    });
  }

  if (Array.isArray(requests.industries) && requests.industries.length > 0) {
    const industryIds = requests.industries.map((i: any) => i.detail.map((d: any) => String(d.id))).flat();
    setValue('industryIds', industryIds, {
      shouldValidate: true,
    });
  }

  if (Array.isArray(requests.occupations) && requests.occupations.length > 0) {
    const occupationIds = requests.occupations.map((o: any) => o.detail.map((d: any) => String(d.id))).flat();
    setValue('occupationIds', occupationIds, {
      shouldValidate: true,
    });
  }

  if (Array.isArray(requests.locations) && requests.locations.length > 0) {
    const locationIds = requests.locations.map((l: any) => l.detail.map((d: any) => String(d.id))).flat();
    setValue('locationIds', locationIds, {
      shouldValidate: true,
    });
  }

  if (typeof requests?.other?.content === 'string') {
    setValue('other', requests.other.content, {
      shouldValidate: true,
    });
  }
};

const useSetInitial = (setValue: SetValue) => {
  const [loadingForPrepare, setLoadingForPrepare] = useState(true);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data?.profiles) {
        const profile = res.data.profiles;
        // プロフィール
        setProfile(profile, setValue);
        setEmail(profile.email);

        // 就業状況
        setWorkStatus(profile, setValue);

        if (profile?.school) {
          // 学歴
          setEducation(profile.school, setValue);
        }
      }

      if (Array.isArray(res.data?.work_histories) && res.data.work_histories.length > 0) {
        // 職務経歴
        setWorkHistories(res.data.work_histories, setValue);
      }

      if (res.data?.skills) {
        const { skills } = res.data;
        if (Array.isArray(skills?.occupation_skills) && skills.occupation_skills.length > 0) {
          // スキル
          setSkills(skills.occupation_skills, setValue);
        }

        // 語学
        setLanguage(skills?.skill, skills?.acquire_languages, setValue);

        if (Array.isArray(skills?.qualifications) && skills.qualifications.length > 0) {
          // 資格
          setQualifications(skills.qualifications, setValue);
        }
      }

      if (res.data?.requests) {
        // 希望条件
        setRequest(res.data.requests, setValue);
      }
    };

    req().then(() => {
      setLoadingForPrepare(false);
    });
  }, [setValue, navigate]);

  return { loadingForPrepare, email };
};

export default useSetInitial;
