import { CircularProgress } from '@mui/material';
import SelectInput from 'components/parts/SelectInput';
import NextButton from 'components/parts/NextButton';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import useGetRequestMaster from 'hooks/Master/useGetRequestMaster';
import { RequestFormData } from 'types';
import ProgressBar from 'components/parts/ProgressBar';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import ItemName from 'components/parts/ItemName';
import styles from './Request.module.scss';

const Request: FC = () => {
  const auth = useAuth();
  useScrollTop();
  useCheckToken();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<RequestFormData>({
    mode: 'onChange',
  });

  const [loadingForPrepare, setLoadingForPrepare] = useState(true);

  // 初期値セット
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const requests = res.data?.requests;

      if (!requests) {
        return;
      }

      if (Array.isArray(requests.locations) && requests.locations.length > 0) {
        const locationIds = requests.locations.map((l: any) => l.detail.map((d: any) => String(d.id))).flat();
        setValue('locationIds', locationIds, {
          shouldValidate: true,
        });
      }
    };

    if (auth.id) {
      req().then(() => {
        setLoadingForPrepare(false);
      });
    }
  }, [auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: RequestFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    let params: Record<string, any> = {};

    if (data.locationIds?.length > 0) {
      params = {
        ...params,
        locations: data.locationIds,
      };
    }

    const token = getToken();
    const res = await axios.put('/api/v18/users/requests', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 処理中フラグを折る
    processing.current = false;
    if (res.status === 200 || res.status === 201) {
      // 登録完了ページに遷移
      navigate('/registration-complete');
    }
  };

  const { locations } = useGetRequestMaster(navigate);

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={7} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>希望情報</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>希望勤務地</ItemName>
                  </th>
                  <td>
                    {locations.length > 0 && !loadingForPrepare ? (
                      <div className={styles.acquireLanguage}>
                        <Controller
                          control={control}
                          name="locationIds"
                          rules={{
                            required: required('希望勤務地'),
                            validate: {
                              format: (values) =>
                                validate(
                                  !Array.isArray(values) ||
                                    values.every((v) =>
                                      isIn(
                                        v,
                                        locations.map((s) => s.id),
                                      ),
                                    ),
                                  '希望勤務地',
                                ),
                            },
                          }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <SelectInput
                                placeholder="選択してください（複数選択可）"
                                isMulti
                                options={locations}
                                value={
                                  value
                                    ? locations
                                        .filter((s) => value.includes(s.id))
                                        .map((s) => ({
                                          value: s.id,
                                          label: s.name,
                                        }))
                                    : []
                                }
                                onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <CircularProgress />
                    )}
                    <p className={styles.errorMessage}>{(errors?.locationIds as any)?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Request;
