import NextButton from 'components/parts/NextButton';
import Select from 'components/parts/Select';
import useGetWorkHistoryMaster from 'hooks/Master/useGetWorkHistoryMaster';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { WorkHistoryFormData } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import ProgressBar from 'components/parts/ProgressBar';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import getCompanyNames from 'requests/getCompanyNames';
import { monthOptions, workOrGraduationYearOptions } from 'utils/options';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import ItemName from 'components/parts/ItemName';
import Textarea from 'components/parts/Textarea';
import styles from './WorkHistory.module.scss';

const WorkHistory: FC = () => {
  const auth = useAuth();
  useScrollTop();
  useCheckToken();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isValid },
  } = useForm<WorkHistoryFormData>({
    mode: 'onChange',
    defaultValues: {
      joinYear: '2010',
      joinMonth: '4',
    },
  });

  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!Array.isArray(res.data.work_histories) || res.data.work_histories.length === 0) {
        return;
      }

      const workHistory = res.data.work_histories[0];

      setValue('id', workHistory.id);

      setValue('companyName', workHistory.company_name, {
        shouldValidate: true,
      });

      if (typeof workHistory.join_year === 'number' && workHistory.join_year > 0) {
        setValue('joinYear', String(workHistory.join_year), {
          shouldValidate: true,
        });
      }
      if (typeof workHistory.join_month === 'number' && workHistory.join_month > 0) {
        setValue('joinMonth', String(workHistory.join_month), {
          shouldValidate: true,
        });
      }
      if (typeof workHistory.leave_year === 'number' && workHistory.leave_year > 0) {
        setValue('leaveYear', String(workHistory.leave_year), {
          shouldValidate: true,
        });
      }
      if (typeof workHistory.leave_month === 'number' && workHistory.leave_month > 0) {
        setValue('leaveMonth', String(workHistory.leave_month), {
          shouldValidate: true,
        });
      }
      if (workHistory.employee_type && typeof workHistory.employee_type.id === 'number') {
        setValue('employeeTypeId', String(workHistory.employee_type.id), {
          shouldValidate: true,
        });
      }
      if (workHistory.work_position && typeof workHistory.work_position.id === 'number') {
        setValue('workPositionId', String(workHistory.work_position.id), {
          shouldValidate: true,
        });
      }
      setValue('salary', workHistory.salary, {
        shouldValidate: true,
      });
      if (Array.isArray(workHistory.industry) && workHistory.industry.length > 0) {
        const industry = workHistory.industry[0];
        if ('detail' in industry && Array.isArray(industry.detail) && industry.detail.length > 0) {
          const detail = industry.detail[0];
          setValue('industryChildId', String(detail.id), {
            shouldValidate: true,
          });
        }
      }
      if (Array.isArray(workHistory.occupation) && workHistory.occupation.length > 0) {
        const occupation = workHistory.occupation[0];
        if ('detail' in occupation && Array.isArray(occupation.detail) && occupation.detail.length > 0) {
          const detail = occupation.detail[0];
          setValue('occupationChildId', String(detail.id), {
            shouldValidate: true,
          });
        }
      }
      if (typeof workHistory.outline === 'string') {
        setValue('outline', workHistory.outline, {
          shouldValidate: true,
        });
      }
    };

    if (auth.id) {
      req();
    }
  }, [auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: WorkHistoryFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    let params: Record<string, any> = {
      company_name: data.companyName,
      employee_type_id: data.employeeTypeId,
      work_position_id: data.workPositionId,
      salary: data.salary,
      industry_child_id: data.industryChildId,
      occupation_child_id: data.occupationChildId,
      outline: data.outline,
    };

    const joinDate = new Date(`${data.joinYear}/${data.joinMonth}/1`);
    if (!(joinDate.getFullYear() === Number(data.joinYear) && joinDate.getMonth() + 1 === Number(data.joinMonth))) {
      // 在籍期間形式エラー
      setError('joinYear', {
        type: 'format',
        message: '在籍期間は正しい形式で入力してください。',
      });
      setError('joinMonth', {
        type: 'format',
        message: '在籍期間は正しい形式で入力してください。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    if (joinDate > new Date()) {
      // 在籍期間形式エラー
      setError('joinYear', {
        type: 'future',
        message: '在籍期間に未来の日付が入力されています。',
      });
      setError('joinMonth', {
        type: 'future',
        message: '在籍期間に未来の日付が入力されています。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    if (data.leaveYear || data.leaveMonth) {
      const leaveDate = new Date(`${data.leaveYear}/${data.leaveMonth}/1`);
      if (
        !(leaveDate.getFullYear() === Number(data.leaveYear) && leaveDate.getMonth() + 1 === Number(data.leaveMonth))
      ) {
        // 在籍期間形式エラー
        setError('leaveYear', {
          type: 'format',
          message: '在籍期間は正しい形式で入力してください。',
        });
        setError('leaveMonth', {
          type: 'format',
          message: '在籍期間は正しい形式で入力してください。',
        });

        // 処理中フラグを折る
        processing.current = false;
        return;
      }

      if (leaveDate > new Date()) {
        // 在籍期間形式エラー
        setError('leaveYear', {
          type: 'future',
          message: '在籍期間に未来の日付が入力されています。',
        });
        setError('leaveMonth', {
          type: 'future',
          message: '在籍期間に未来の日付が入力されています。',
        });

        // 処理中フラグを折る
        processing.current = false;
        return;
      }

      if (joinDate > leaveDate) {
        // 在籍開始期間 > 在籍終了期間エラー
        setError('leaveYear', {
          type: 'future',
          message: '在籍終了年月に在籍開始年月より過去の年月が入力されています。',
        });
        setError('leaveMonth', {
          type: 'future',
          message: '在籍終了年月に在籍開始年月より過去の年月が入力されています。',
        });

        // 処理中フラグを折る
        processing.current = false;
        return;
      }

      params = {
        ...params,
        leave_year: data.leaveYear,
        leave_month: data.leaveMonth,
      };
    } else {
      params = {
        ...params,
        leave_year: '',
        leave_month: '',
      };
    }

    params = {
      ...params,
      join_year: data.joinYear,
      join_month: data.joinMonth,
    };

    const token = getToken();
    let res = null;

    if (data?.id) {
      // 更新
      res = await axios.put(`/api/v18/users/work-histories/${data.id}`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      // 初回登録
      res = await axios.post('/api/v18/users/work-histories', params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // 処理中フラグを折る
    processing.current = false;

    if (res && (res.status === 200 || res.status === 201)) {
      // 次のページに飛ばす (語学)
      navigate('/language');
    }
  };

  const { workPositions, employeeTypes, industries, occupations } = useGetWorkHistoryMaster(navigate);

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={4} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>職務情報</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>直近の在籍企業名</ItemName>
                  </th>
                  <td>
                    <div
                      className={`${styles.companyName} ${
                        errors?.companyName && errors.companyName?.message ? styles.errorForm : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name="companyName"
                        rules={{
                          required: required('企業名'),
                        }}
                        render={({ field: { onChange, value } }) => {
                          const companyNameValue = value
                            ? {
                                value,
                                label: value,
                              }
                            : [];
                          return (
                            <AsyncCreatableSelectInput
                              placeholder="企業名を入力してください"
                              loadOptions={getCompanyNames}
                              value={companyNameValue}
                              onChange={(val: any) => onChange(val?.value ?? '')}
                            />
                          );
                        }}
                      />
                    </div>
                    <p className={styles.errorMessage}>{errors?.companyName?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の雇用形態</ItemName>
                  </th>
                  <td>
                    <Select
                      className={errors?.employeeTypeId?.message ? styles.errorForm : ''}
                      placeholder="選択してください"
                      options={employeeTypes}
                      control={control}
                      useFormRegisterReturn={register('employeeTypeId', {
                        required: required('雇用形態'),
                        validate: {
                          format: (value) =>
                            validate(
                              isIn(
                                value,
                                employeeTypes.map((et) => et.id),
                              ),
                              '雇用形態',
                            ),
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.employeeTypeId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の役職・クラス</ItemName>
                  </th>
                  <td>
                    <Select
                      className={errors?.workPositionId?.message ? styles.errorForm : ''}
                      placeholder="選択してください"
                      options={workPositions}
                      control={control}
                      useFormRegisterReturn={register('workPositionId', {
                        required: required('役職・クラス'),
                        validate: {
                          format: (value) =>
                            validate(
                              isIn(
                                value,
                                workPositions.map((wp) => wp.id),
                              ),
                              '役職',
                            ),
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.workPositionId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の企業の在籍期間</ItemName>
                  </th>
                  <td>
                    <div className={styles.formGroup}>
                      <div className={styles.formGroupLeft}>
                        <Select
                          className={errors?.joinYear?.message ? styles.errorForm : ''}
                          options={workOrGraduationYearOptions()}
                          control={control}
                          useFormRegisterReturn={register('joinYear', {
                            required: required('在籍期間'),
                          })}
                          placeholder="年"
                        />
                        <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>年</span>
                        <Select
                          className={errors?.joinMonth?.message ? styles.errorForm : ''}
                          options={monthOptions}
                          control={control}
                          useFormRegisterReturn={register('joinMonth', {
                            required: required('在籍期間'),
                          })}
                          placeholder="月"
                        />
                        <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>月</span>
                      </div>
                      <div className={styles.formGroupCenter}>〜</div>
                      <div className={styles.formGroupRight}>
                        <Select
                          className={errors?.leaveYear?.message ? styles.errorForm : ''}
                          options={workOrGraduationYearOptions()}
                          control={control}
                          useFormRegisterReturn={register('leaveYear')}
                          placeholder="年"
                        />
                        <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>年</span>
                        <Select
                          className={errors?.leaveMonth?.message ? styles.errorForm : ''}
                          options={monthOptions}
                          control={control}
                          useFormRegisterReturn={register('leaveMonth')}
                          placeholder="月"
                        />
                        <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>月</span>
                      </div>
                    </div>
                    <p className={styles.errorMessage}>
                      {errors?.joinYear?.message ||
                        errors?.joinMonth?.message ||
                        errors?.leaveYear?.message ||
                        errors?.leaveMonth?.message}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の年収</ItemName>
                  </th>
                  <td>
                    <div className={styles.salaryWrapper}>
                      <input
                        className={errors?.salary?.message ? styles.errorForm : ''}
                        type="number"
                        placeholder="年収を入力してください"
                        {...register('salary', {
                          required: required('直近の年収'),
                        })}
                      />
                      <span className={`${styles.marginLeft8}`}>万円</span>
                    </div>
                    <p className={styles.errorMessage}>{errors?.salary?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の業界</ItemName>
                  </th>
                  <td>
                    <Select
                      className={errors?.industryChildId?.message ? styles.errorForm : ''}
                      placeholder="選択してください"
                      optgroups={industries}
                      control={control}
                      useFormRegisterReturn={register('industryChildId', {
                        required: required('業界'),
                        validate: {
                          format: (value) =>
                            validate(isIn(value, industries.map((i) => i.options.map((o) => o.id)).flat()), '業界'),
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.industryChildId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>直近の職種</ItemName>
                  </th>
                  <td>
                    <Select
                      className={errors?.occupationChildId?.message ? styles.errorForm : ''}
                      placeholder="選択してください"
                      optgroups={occupations}
                      control={control}
                      useFormRegisterReturn={register('occupationChildId', {
                        required: required('職種'),
                        validate: {
                          format: (value) =>
                            validate(isIn(value, occupations.map((i) => i.options.map((o) => o.id)).flat()), '職種'),
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.occupationChildId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName>直近の職務概要</ItemName>
                  </th>
                  <td>
                    {/* eslint-disable-next-line */}
                    <Textarea
                      control={control}
                      useFormRegisterReturn={register('outline')}
                      placeholder={`具体的なスキルや経験を入力してください。
例）
プロジェクトメンバー10名をマネジメントしながら、新規事業を立ち上げ、2年間で10億円の売り上げを達成した。`}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default WorkHistory;
