import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Optgroup, Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetWorkHistoryMaster = (
  navigate: NavigateFunction,
): {
  workPositions: Option[];
  employeeTypes: Option[];
  industries: Optgroup[];
  occupations: Optgroup[];
} => {
  const [workPositions, setWorkPositions] = useState<Option[]>([]);
  const [employeeTypes, setEmployeeTypes] = useState<Option[]>([]);
  const [industries, setIndustries] = useState<Optgroup[]>([]);
  const [occupations, setOccupations] = useState<Optgroup[]>([]);
  useEffect(() => {
    /** 役職マスター取得 */
    const reqWorkPosition = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/work-positions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((workPosition) => {
          return {
            id: String(workPosition.id),
            name: workPosition.name,
          };
        });
        setWorkPositions(data);
      }
    };

    /** 雇用形態マスター取得 */
    const reqWorkEmployeeType = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/employee-types', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((employeeType) => {
          return {
            id: String(employeeType.id),
            name: employeeType.name,
          };
        });
        setEmployeeTypes(data);
      }
    };

    /** 業界マスター取得 */
    const reqIndustry = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/industries', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((industry) => {
          return {
            label: industry.name,
            options: industry.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
            })),
          };
        });
        setIndustries(data);
      }
    };

    /** 職種マスター取得 */
    const reqOccupation = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/occupations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((occupation) => {
          return {
            label: occupation.name,
            options: occupation.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
            })),
          };
        });
        setOccupations(data);
      }
    };

    reqWorkPosition();
    reqWorkEmployeeType();
    reqIndustry();
    reqOccupation();
  }, [navigate]);

  return {
    workPositions,
    employeeTypes,
    industries,
    occupations,
  };
};

export default useGetWorkHistoryMaster;
