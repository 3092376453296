import { UseFormSetError } from 'react-hook-form';
import { MypageEditFormData, Option } from 'types';
import axios from 'utils/axios';
import { isIn } from 'utils/validation';

/**
 * スキル、語学、資格を更新する
 */
const updateSkill = async (
  setError: UseFormSetError<MypageEditFormData>,
  userId: string,
  token: string | null,
  data: MypageEditFormData,
  skillLevels: Option[],
) => {
  let params: Record<string, any> = {
    skill: {
      language_id: data.languageId,
      test_jlpt_grade_id: data.testJlptGradeId,
      test_hsk_grade_id: data.testHskGradeId,
      test_chinese_grade_id: data.testChineseGradeId,
      toeic_score: data.toeicScore,
      toefl_score: data.toeflScore,
    },
    acquire_languages: [],
    qualifications: [],
    occupation_skills: [],
  };

  let result = true;
  if (Number(data.toeicScore) > 990 || Number(data.toeicScore) < 0) {
    setError('toeicScore', {
      type: 'format',
      message: 'TOEICは正しい形式で入力してください。',
    });
    result = false;
  }

  if (Number(data.toeflScore) > 120 || Number(data.toeflScore) < 0) {
    setError('toeflScore', {
      type: 'format',
      message: 'TOEFLは正しい形式で入力してください。',
    });
    result = false;
  }

  data.skills.forEach((s, index) => {
    if (s.skill) {
      if (!s.skillLevel) {
        setError(`skills.${index}.skillLevel`, {
          type: 'required',
          message: '経験年数を入力してください。',
        });
        result = false;
      }
    }
    if (s.skillLevel) {
      if (!s.skill) {
        setError(`skills.${index}.skill`, {
          type: 'required',
          message: 'スキルを入力してください。',
        });
        result = false;
      }
      if (
        !isIn(
          s.skillLevel,
          skillLevels.map((sl) => sl.id),
        )
      ) {
        setError(`skills.${index}.skillLevel`, {
          type: 'format',
          message: '経験年数は正しい形式で入力してください。',
        });
      }
    }
  });

  if (!result) {
    return false;
  }

  // 第二言語
  if (data.acquireLanguage?.length > 0) {
    const acquireLanguage = data.acquireLanguage.map((al) => {
      const alSplit = al.split('-');
      return {
        language_id: alSplit[0],
        language_level_id: alSplit[1],
      };
    });

    params = {
      ...params,
      acquire_languages: acquireLanguage,
    };
  }

  // スキル
  if (data.skills?.length > 0) {
    const occupationSkills = data.skills
      .filter((s) => s.skill && s.skillLevel)
      .map((s) => {
        let param: Record<string, any> = {
          occupation_skill_level_id: s.skillLevel,
        };

        const re = /#[0-9]*$/;
        const match = re.exec(s.skill);
        if (match && match.length > 0) {
          param = {
            ...param,
            occupation_skill: null,
            occupation_skill_id: match[0].replace('#', ''),
          };
        } else {
          param = {
            ...param,
            occupation_skill: s.skill,
            occupation_skill_id: null,
          };
        }

        return param;
      });
    params = {
      ...params,
      occupation_skills: occupationSkills,
    };
  }

  // 資格
  if (data.qualificationIds?.length > 0) {
    const qualifications = data.qualificationIds.map((q) => {
      const re = /#[0-9]*$/;
      const match = re.exec(q);
      let param: Record<string, any> = {};
      if (match && match.length > 0) {
        param = {
          ...param,
          qualification_id: match[0].replace('#', ''),
        };
      } else {
        param = {
          ...param,
          qualification: q,
        };
      }

      return param;
    });

    params = {
      ...params,
      qualifications,
    };
  }

  await axios.put('/api/v18/users/skills', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return true;
};

export default updateSkill;
