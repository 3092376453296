import axios from 'utils/axios';
import { getToken } from 'utils/token';

const getUniversityName = async (universityName: string, navigate: (path: string) => void) => {
  if (!universityName) {
    return [];
  }

  const token = getToken();
  if (!token) {
    navigate('/login');
  }
  const params = new URLSearchParams({
    keyword: universityName,
  });

  const res = await axios
    .get('/api/v18/master/universities/search', {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(() => {
      // noop
    });

  if (res && res.status === 200 && Array.isArray(res.data)) {
    const data = res.data.map((u: any) => {
      return {
        value: u.name,
        label: u.name,
      };
    });

    const uniqueData = data.filter(
      (element: any, index: any, self: any) => self.findIndex((e: any) => e.value === element.value) === index,
    );
    return uniqueData;
  }

  return [];
};

export default getUniversityName;
