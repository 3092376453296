import { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from 'typescript-cookie';
import { removeToken } from 'utils/token';
import { getMobileOS } from 'utils/common';
import { CSSTransition } from 'react-transition-group';
import ArrowDown from 'images/arrow_down.svg';
import iconJaU from 'images/icon_JaU.png';
import image from 'images/logo-01.png';
import useClickOutside from 'hooks/useClickOutside';
import useGetUser from 'hooks/useGetUser';
import styles from './Header.module.scss';

type Props = {
  hasLogin?: boolean;
  isMypage?: boolean;
};

const Header: FC<Props> = ({ hasLogin, isMypage }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const os = getMobileOS();
  const [showDownload, setShowDownload] = useState<boolean>(getCookie('closeApp') !== '1');
  const [user, setUser] = useState<any>(null);
  const [toggle, setToggle] = useState(false);

  const { data, isLoading } = useGetUser();
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (data && 'data' in data) {
      setUser(data.data);

      if (data.data.profiles?.name?.last_name == null || data.data.profiles?.name?.first_name == null) {
        navigate('/profile');
      }
    }
  }, [data, isLoading, navigate]);

  const isSameCurrentPath = (...pathnames: string[]) => {
    return pathnames.includes(location.pathname);
  };
  const logout = async () => {
    setToggle(false);
    setUser(null);
    removeToken();
    navigate('/login');
  };

  const [isTrigger, setIsTrigger] = useState(false);
  const openToggle = () => {
    setToggle(true);
    setIsTrigger(true);
  };

  useEffect(() => {
    if (toggle && isTrigger) {
      setIsTrigger(false);
    }
  }, [toggle, isTrigger]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => {
    if (!isTrigger) {
      setToggle(false);
    }
  });

  const routerPush = (path: string) => {
    setToggle(false);
    navigate(path);
  };

  return (
    <header className={`${styles.header}`}>
      {showDownload && os !== 'Other' && (
        <div className={styles.appDownload}>
          <div className={`${styles.container}`}>
            <div className={styles.headerSignLeft}>
              {/* eslint-disable-next-line */}
              <button
                type="button"
                className={styles.close}
                onClick={() => {
                  setCookie('closeApp', '1', { expires: 1 });
                  setShowDownload(false);
                }}
              />
              <img className={styles.icon} src={iconJaU} alt="JaU" />
              <div className={styles.sellingPoint}>
                通知を受け取って
                <br />
                より便利に
              </div>
            </div>
            <div className={styles.headerSignRight}>
              <Link
                to={(() => {
                  let href = 'https://jauapp.page.link/';
                  if (os === 'iOS') {
                    href += 'sQXj';
                  } else if (os === 'Android') {
                    href += 'V9uh';
                  }
                  return href;
                })()}
                className={styles.install}
                target="_blank"
                rel="noreferrer"
              >
                インストール
              </Link>
            </div>
          </div>
        </div>
      )}
      <nav className={toggle ? styles.openMenu : ''}>
        <div className={`${styles.container}`}>
          <Link className={`${styles.headerLogo}`} to="/">
            <img src={image} alt="ジャーヨウ" />
          </Link>
          {isMypage && (
            <>
              <div className={`${styles.headerSignLeft} ${isSameCurrentPath('/jobs') ? styles.selected : ''}`}>
                <Link className={styles.isPC} to="/jobs">
                  求人一覧
                </Link>
              </div>
              <div className={styles.headerSignRight}>
                {user?.is_valid && (
                  <>
                    <div className={styles.pc}>
                      <Link
                        className={`${styles.headerItem} ${isSameCurrentPath('/messages') ? styles.selected : ''}`}
                        to="/messages"
                      >
                        メッセージ
                      </Link>
                    </div>
                  </>
                )}
                {user && (
                  <div className={styles.toggle}>
                    <button className={styles.toggleTitle} type="button" onClick={openToggle}>
                      {user?.profiles?.name?.last_name && user?.profiles?.name?.first_name && (
                        <>
                          <div
                            className={styles.usericon}
                            style={
                              user?.profiles?.file_path && { backgroundImage: `url(${user?.profiles?.file_path})` }
                            }
                          >
                            <></>
                          </div>
                          <span
                            className={styles.username}
                          >{`${user?.profiles?.name?.last_name} ${user?.profiles?.name?.first_name}`}</span>{' '}
                          さん
                          <img className={`${styles.icon} ${toggle && styles.open}`} src={ArrowDown} />
                        </>
                      )}
                    </button>
                    <button className={styles.toggleTitleSp} type="button" onClick={openToggle}>
                      <span>メニュー</span>
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
          {hasLogin && !user && (
            <div className={`${styles.headerSignRight}`}>
              <Link to={user ? '/mypage' : '/login'}>
                <div className={styles.login}>
                  <span className={styles.loginText}>ログイン</span>
                </div>
              </Link>
              <Link to="/signup">
                <div className={styles.signup}>新規会員登録（無料）</div>
                <div className={styles.signup_sp}>
                  <span className={styles.signupText}>会員登録</span>
                </div>
              </Link>
            </div>
          )}
          <CSSTransition
            in={toggle}
            timeout={0}
            classNames={{
              enterActive: styles.menuEnterActive,
              enterDone: styles.menuEnterDone,
            }}
          >
            <div>
              <div className={styles.menuBack}>
                <div className={styles.close}>
                  <span>閉じる</span>
                </div>
              </div>
              <div ref={ref} className={styles.menu}>
                {user?.profiles?.name?.last_name && user?.profiles?.name?.first_name && (
                  <div className={styles.userSP}>
                    <div
                      className={styles.usericon}
                      style={user?.profiles?.file_path && { backgroundImage: `url(${user?.profiles?.file_path})` }}
                    >
                      <></>
                    </div>
                    <span
                      className={styles.username}
                    >{`${user?.profiles?.name?.last_name} ${user?.profiles?.name?.first_name}`}</span>{' '}
                    さん
                  </div>
                )}
                <button type="button" className={`${styles.item} ${styles.sp}`} onClick={() => routerPush('/messages')}>
                  <span>メッセージ</span>
                  <span className={styles.icon} />
                </button>
                <button type="button" className={styles.item} onClick={() => routerPush('/mypage')}>
                  <span>登録情報</span>
                  <span className={styles.icon} />
                </button>
                <button type="button" className={styles.item} onClick={() => routerPush('/setting')}>
                  <span>設定</span>
                  <span className={styles.icon} />
                </button>
                <button className={`${styles.item} ${styles.logout}`} type="button" onClick={logout}>
                  <span>ログアウト</span>
                </button>
              </div>
            </div>
          </CSSTransition>
        </div>
      </nav>
    </header>
  );
};

Header.defaultProps = {
  hasLogin: false,
  isMypage: false,
};

export default Header;
