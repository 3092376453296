import axios from 'utils/axios';
import { AuthData } from 'types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, setToken } from 'utils/token';
import { useCheckToken } from './useCheckToken';

const useAuth = (): AuthData => {
  useCheckToken();
  const navigate = useNavigate();
  const [authData, setAuthData] = useState<AuthData>({
    id: '',
    isValid: false,
  });

  useEffect(() => {
    const auth = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/user/refresh?device_type=3', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200) {
        const tokenRefreshed = res.data.auth.access_token;
        setToken(tokenRefreshed);
        setAuthData({
          id: res.data.user.id,
          isValid: res.data.user.is_valid,
        });
      }
    };

    auth();
  }, [navigate]);

  return authData;
};

export default useAuth;
