import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken, setToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import axios from 'utils/axios';
import styles from './AccountVerify.module.scss';

const AccountVerify: FC = () => {
  useCheckToken();
  const query = new URLSearchParams(useLocation().search);

  const expires = query.get('expires');
  const id = query.get('id');
  const t = query.get('t');
  const signature = query.get('signature');
  const navigate = useNavigate();

  let path: string;
  if (t !== null) {
    path = `/api/v18/user/register/verify?expires=${expires}&id=${id}&t=${t}&signature=${signature}`;
    axios
      .get(path)
      .then((res) => {
        const { access_token: token, expiration } = res.data.auth;
        setToken(token, expiration);
        navigate('/account-verify/done');
      })
      .catch(() => {
        navigate('/account-verify/failure');
      });
  } else {
    const params = new URLSearchParams({
      expires: expires as string,
      id: id as string,
      signature: signature as string,
    });

    const token = getToken();
    axios
      .get('/api/v18/user/verify', { params, headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        navigate('/mail-verify/done');
      })
      .catch(() => {
        navigate('/account-verify/failure');
      });
  }

  return <div className={`${styles.textCenter}`}>アカウントを認証中です。お待ち下さい。</div>;
};

export default AccountVerify;
