import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetSkillMaster = (
  navigate: NavigateFunction,
): {
  skillLevels: Option[];
} => {
  // スキルレベルマスタ
  const [skillLevels, setSkillLevels] = useState<Option[]>([]);

  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/occupation-levels', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data
          .map((l) => ({
            id: String(l.id),
            name: l.name,
          }))
          .flat();

        setSkillLevels([...data]);
      }
    };

    req();
  }, [navigate]);

  return {
    skillLevels,
  };
};

export default useGetSkillMaster;
