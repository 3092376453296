import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Option, Region } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetProfileMaster = (
  navigate: NavigateFunction,
): {
  countries: Option[];
  regions: Region[];
} => {
  const [countries, setCountries] = useState<Option[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);

  // 居住国マスター取得
  useEffect(() => {
    const countriesReq = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v18/master/countries', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const countriesData = res.data.map((country) => ({
          id: String(country.id),
          name: country.name,
        }));
        setCountries(countriesData);

        const regionsData = res.data
          .map((country) =>
            country.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
              countryId: String(country.id),
            })),
          )
          .flat();

        setRegions(regionsData);
      }
    };

    countriesReq();
  }, [navigate]);

  return {
    countries,
    regions,
  };
};

export default useGetProfileMaster;
