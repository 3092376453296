import { FC, useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import useGetMypage from 'hooks/useGetMypage';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import useScrollTop from 'hooks/useScrollTop';
import Modal from 'components/parts/Modal';
import styles from './Mypage.module.scss';

const Mypage: FC = () => {
  useAuth();
  useScrollTop();

  const { profile, workStatus, workHistories, skill, education, language, qualification, request, userDescription } =
    useGetMypage();

  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>('default');
  const location = useLocation();

  useEffect(() => {
    if (userDescription.authenticated !== undefined && !userDescription.authenticated) {
      setShowModal(true);
    }
    if (location.state) {
      setShowModal(true);
      setModalName('done');
    }
  }, [userDescription.authenticated, location.state]);

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Modal
            email={userDescription.address}
            isShowModal={isShowModal}
            setShowModal={setShowModal}
            initModalName={modalName}
          />
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <Typography color="text.primary">登録情報</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>登録情報</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>
              基本情報
              {userDescription.userOriginalId && (
                <span className={styles.userOriginalId}>{userDescription.userOriginalId}</span>
              )}
            </h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>氏名</th>
                    <td>
                      {profile.lastName} {profile.firstName}
                    </td>
                  </tr>
                  <tr>
                    <th>フリガナ</th>
                    <td>
                      {profile.lastKana} {profile.firstKana}
                    </td>
                  </tr>
                  <tr>
                    <th>性別</th>
                    <td>{profile.sex}</td>
                  </tr>
                  <tr>
                    <th>生年月日</th>
                    <td>{profile.birthday}</td>
                  </tr>
                  <tr>
                    <th>電話番号</th>
                    <td>{profile.phone}</td>
                  </tr>
                  <tr>
                    <th>居住国</th>
                    <td>{profile.country}</td>
                  </tr>
                  <tr>
                    <th>居住地域</th>
                    <td>{profile.region}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>学歴</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>最終学歴</th>
                    <td>{education.educationName}</td>
                  </tr>
                  <tr>
                    <th>学校名</th>
                    <td>{education.schoolName}</td>
                  </tr>
                  <tr>
                    <th>学科名</th>
                    <td>{education.department}</td>
                  </tr>
                  <tr>
                    <th>卒業年月（予定）</th>
                    <td>{education.graduateDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>就業状況</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>就業状況</th>
                    <td>{workStatus}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          {workHistories.map((workHistory, index) => (
            <section key={`${workHistory.companyName}${workHistory.join}`} className={`${styles.panelDefault}`}>
              <h2 className={`${styles.panelDefaultTitle}`}>{`職務情報${
                index === 0 ? '（直近の所属企業）' : index + 1
              }`}</h2>
              <div className={`${styles.panelDefaultContent}`}>
                <table className={`${styles.tableDefault}`}>
                  <tbody>
                    <tr>
                      <th>在籍企業名</th>
                      <td>{workHistory.companyName}</td>
                    </tr>
                    <tr>
                      <th>雇用形態</th>
                      <td>{workHistory.employeeType}</td>
                    </tr>
                    <tr>
                      <th>役職・クラス</th>
                      <td>{workHistory.workPosition}</td>
                    </tr>
                    <tr>
                      <th>企業の在籍期間</th>
                      <td>
                        {workHistory.join !== '-年-月' && workHistory.leave === '-年-月'
                          ? '在籍中'
                          : `${workHistory.join}〜${workHistory.leave}`}
                      </td>
                    </tr>
                    <tr>
                      <th>年収</th>
                      <td>{workHistory.salary}万円</td>
                    </tr>
                    <tr>
                      <th>業界</th>
                      <td>{workHistory.industryChild}</td>
                    </tr>
                    <tr>
                      <th>職種</th>
                      <td>{workHistory.occupationChild}</td>
                    </tr>
                    <tr>
                      <th>職務概要</th>
                      <td>{workHistory.outline}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          ))}
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>スキル</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>スキルと経験年数</th>
                    <td>
                      {skill.map((s) => (
                        <div key={s.name}>{s.name}</div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>語学</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>母国語</th>
                    <td>{language.native}</td>
                  </tr>
                  <tr>
                    <th>第二言語</th>
                    <td>
                      {language.acquire.length > 0 ? (
                        language.acquire.map((a) => <div key={a.name}>{a.name}</div>)
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>JLPT 日本語能力試験</th>
                    <td>{language.jlpt}</td>
                  </tr>
                  <tr>
                    <th>HSK</th>
                    <td>{language.hsk}</td>
                  </tr>
                  <tr>
                    <th>中国語検定</th>
                    <td>{language.chinese}</td>
                  </tr>
                  <tr>
                    <th>TOEIC</th>
                    <td>{language.toeic}点</td>
                  </tr>
                  <tr>
                    <th>TOEFL</th>
                    <td>{language.toefl}点</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>資格</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>資格</th>
                    <td>
                      {qualification.length > 0 ? (
                        qualification.map((q) => <div key={q.name}>{q.name}</div>)
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>希望情報</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>希望年収</th>
                    <td>{request.salary}</td>
                  </tr>
                  <tr>
                    <th>希望業界</th>
                    <td>
                      {request.industry.length > 0 ? (
                        request.industry.map((q) => <div key={q.name}>{q.name}</div>)
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>希望職種</th>
                    <td>
                      {request.occupation.length > 0 ? (
                        request.occupation.map((q) => <div key={q.name}>{q.name}</div>)
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>希望勤務地</th>
                    <td>
                      {request.region.map((q) => (
                        <div key={q.name}>{q.name}</div>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td>{request.other}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>メールアドレス</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{userDescription.address}</td>
                    <td>
                      {!userDescription.authenticated && (
                        <div className={`${styles.unauthenticated}`}>
                          <span>メールを送付しています、</span>
                          <span>メールアドレスを認証してください。</span>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <Link to="/mypage/edit" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
            登録情報の編集
            <span className={`${styles.materialIcons}`}>
              <ArrowForwardIosRoundedIcon />
            </span>
          </Link>
        </div>
      </main>
    </>
  );
};

export default Mypage;
