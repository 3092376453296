import { FC, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import axios from 'utils/axios';
import { useForm } from 'react-hook-form';
import { setToken } from 'utils/token';
import { pattern, required } from 'utils/validation';
import useCheckNotLogin from 'hooks/useCheckNotLogin';
import useScrollTop from 'hooks/useScrollTop';
import styles from './Login.module.scss';

type FormData = {
  email: string;
  password: string;
  message: string;
};

const Login: FC = () => {
  // ログイン中であればマイページ登録情報に飛ばす
  useCheckNotLogin();
  useScrollTop();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const processing = useRef(false);
  const navigate = useNavigate();
  const onSubmit = async (data: FormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const paramsLogin = new URLSearchParams({
      email: data.email,
      password: data.password,
      device_type: '3',
    });

    try {
      const resLogin = await axios.post('/api/v18/user/login', paramsLogin);
      // 処理中フラグを折る
      processing.current = false;
      if (resLogin.status === 200) {
        const { access_token: token, expiration } = resLogin.data.auth;
        setToken(token, expiration);

        // マイページ遷移
        navigate('/mypage');
      }
    } catch (e) {
      // 処理中フラグを折る
      processing.current = false;
      setErrorMessage('メールアドレスもしくはパスワードが正しくありません。');
    }
  };

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <Typography color="text.primary">ログイン</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>ログイン</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>JaUにログインする</h2>
            <form className={`${styles.panelDefaultContent}`} onSubmit={handleSubmit(onSubmit)} noValidate>
              <table className={`${styles.tableDefault}`}>
                <tbody>
                  <tr>
                    <th>メールアドレス</th>
                    <td>
                      <input
                        className={errors?.email?.message ? styles.errorForm : ''}
                        type="email"
                        placeholder="メールアドレスを入力してください"
                        {...register('email', {
                          required: required('メールアドレス'),
                          pattern: pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'メールアドレス'),
                        })}
                      />
                      <p className={styles.errorMessage}>{errors?.email?.message}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>パスワード</th>
                    <td>
                      <input
                        className={errors?.password?.message ? styles.errorForm : ''}
                        type="password"
                        placeholder="パスワードを入力してください"
                        {...register('password', {
                          required: required('パスワード'),
                        })}
                      />
                      <p className={styles.errorMessage}>{errors?.password?.message}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={`${styles.ruleSect}`} />
              <p className={styles.errorMessage}>{errorMessage}</p>
              <button
                type="submit"
                className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd} ${styles.marginBottomMd}`}
              >
                ログイン
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </button>
              <Link to="/forget-password" className={`${styles.ruleForget}`}>
                パスワードを忘れた方はこちら
              </Link>
            </form>
          </section>

          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>まだ登録していない方</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.marginBottomMd}`}>
                まだ登録していない方は「新規会員登録（無料）」をしてください。
              </p>
              <Link to="/signup" className={`${styles.buttonRound} ${styles.buttonGhost} ${styles.buttonMd}`}>
                新規登録（無料）
              </Link>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Login;
